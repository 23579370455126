import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '',
    paragraph: '',
  };

  return (
    <section {...props} className={outerClasses} id="Downloads">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
                <h3 className="mt-0 mb-12">Your personal data is secure</h3>
                <p className="m-0">
                  When you make a purchase, VPay uses a signature to your face and a number specific to your device as well as a unique transaction code. Your card number is never stored on your device or on Venova servers. And it is also not transmitted to the shops at the time of payment.
                </p>
              </div>
              <div
                className={classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item"
              >
                <Image src={require('./../../assets/images/features-split-image-01.png')} alt="Features split 01" width={528} height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
                <h3 className="mt-0 mb-12">
                  You have a business? Discover how you can power it up by{' '}
                  <a href="https://vpay.venova.app" target="_blank" rel="noopener noreferrer">
                    VPay.
                  </a>
                </h3>
                <p className="m-0">
                  Product coming soon! Book your personalized demo.
                  <br />
                  <br />
                  <a href="mailto:contact@venova.app">
                    <button className="button button-primary button-wide-mobile button-sm">Contact Us</button> {/* Button color changed to blue */}
                  </a>
                </p>
              </div>
              <div
                className={classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item"
              >
                <Image src={require('./../../assets/images/features-split-image-02.png')} alt="Features split 02" width={528} height={396} />
              </div>
            </div>

            <div className="split-item">
           {/*<div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
                <h3 className="mt-0 mb-12">
                  Our wallet is built-in for the next generation of payment method users
                </h3>
                <p className="m-0">
                  VPay has a set of services and advantages for users of the new payment generation.
                </p>
              </div>*/}

            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
              <h3 className="mt-0 mb-12">
                Our wallet is built-in for the next generation of payment method users
              </h3>
              <p className="m-0">
                VPay has a set of services and advantages for users of the new payment generation:
                <ul>
                  <li>
                    <i className="icon-secure"></i> Secure and simple online and in-store payments.
                  </li>
                  <li>
                    <i className="icon-facial-recognition"></i> Facial recognition payments.
                  </li>
                  <li>
                    <i className="icon-seamless"></i> Seamless payment system.
                  </li>
                  <li>
                    <i className="icon-convenience"></i> Convenience: Make payments quickly and easily with a simple and intuitive interface.
                  </li>
                </ul>
              </p>
            </div>
              
              <div
                className={classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item"
              >
                <Image src={require('./../../assets/images/features-split-image-03.png')} alt="Features split 03" width={528} height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
